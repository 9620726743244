import React, { useState } from "react";
import Modal from "./modal/modal";
import "../css/main.css";

const ProjectData = {
  projects: {
    releasedGames: [{}],
    gamejams: [
      {
        title: "Were Car",
        date: "2017",
        cover: "/img/projects/gamejams/werecar/wereCarMain.png",
        screenshots: ["/img/projects/gamejams/werecar/wereCarMain.png"],
        role: "Developer & designer",
        tools: "Unity",
        jam: {
          name: "Cherry Jam",
          url: "https://itch.io/jam/game-dev-network-cherry-jam",
        },
        platforms: "Desktop",
        description: `Created in 48 hours for GameDevNetwork's Cherry Jam in 2017. I worked solo on this game; programming the gameplay along with creating the art and music.
        The jam's theme was 'Dungeons'.
        
        Were Car is a 80's synthwave "rogue-ish" crawler. You play as a cop who has been transformed in a car, 
        and must take down gun toting gangs by running them down!

        Instead of a typical dungeon, you drive around a city with a procedural layout.`,
      },
      {
        title: "Were Car",
        date: "2017",
        cover: "img/projects/gamejams/werecar/wereCarMain.png",
        screenshots: ["img/projects/gamejams/werecar/wereCarMain.png"],
        role: "Developer & designer",
        tools: "Unity",
        platforms: "Desktop",
        description: "",
      },
    ],
    otherProjects: [{}],
  },
};

const Projects = () => {
  const [status, setStatus] = useState(false);
  const [modalData, setModalData] = useState({});

  const elements = [];
  ProjectData.projects.gamejams.forEach((game) => {
    elements.push(
      <div className="contentItem">
        <h2 className="projectTitle">
          {game.title} - {game.date}
        </h2>
        <div className="projectContainer">
          <div className="projectImage">
            <img alt={game.title} src={game.cover} />
          </div>

          <div className="projectDescription">
            <div class="blockTextLine">
              <p class="blockTextLineBold">Role: </p>
              <p class="blockTextLineDetails">{game.role}</p>
            </div>
            <div class="blockTextLine">
              <p class="blockTextLineBold">Tools & Tech: </p>
              <p class="blockTextLineDetails">{game.tools}</p>
            </div>
            {game.client !== undefined && game.client != null ? (
              <div class="blockTextLine">
                <p class="blockTextLineBold">Client: </p>
                <p class="blockTextLineDetails">{game.client}</p>
              </div>
            ) : (
              ""
            )}
            {game.jam !== undefined && game.jam != null ? (
              <div class="blockTextLine">
                <p class="blockTextLineBold">Game Jam: </p>
                <p class="blockTextLineDetails">
                  <a href={game.jam.url}>{game.jam.name}</a>
                </p>
              </div>
            ) : (
              ""
            )}
            <a
              class="blockMoreLink"
              href="/"
              onClick={(event) => {
                setStatus(true);
                setModalData(game);
                event.preventDefault();
              }}
            >
              More info
            </a>
          </div>
        </div>
      </div>
    );
  });

  return (
    <>
      {status && (
        <Modal closeModal={() => setStatus(false)}>
          <p>{modalData.description}</p>
        </Modal>
      )}

      {/*<!-- Column -->*/}
      <div className="contentColumn">
        {/*<!-- Content -->*/}
        <div className="contentColumnFullHeight">
          <div className="contentblock">
            <div className="blockHeader">
              <p>Released Games</p>
            </div>
            {elements}
          </div>
        </div>
      </div>

      {/*<!-- Column -->*/}
      <div className="contentColumn">
        {/*<!-- Content -->*/}
        <div className="contentColumnFullHeight">
          <div className="contentblock">
            <div className="blockHeader">
              <p>Released Games</p>
            </div>
            {elements}
          </div>
        </div>
      </div>
    </>
  );
};

export default Projects;
