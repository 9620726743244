import React from "react";
import "./modal.css";
import FontAwesome from "react-fontawesome";

const Modal = (props) => {
  const { closeModal } = props;

  const closeicon = () => (
    <FontAwesome name="times" onClick={closeModal} className="closeButton" />
  );

  return (
    <div className="overlay">
      <div className="content">
        {closeicon()}
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
