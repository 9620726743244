import React from "react";
import "../../css/main.css";
import "./style.css";
import Timeline from "../timeline/timeline";

const ResumeData = {
  sections: {
    releasedGames: [{}],
    workExperience: [
      {
        title: "Game Development Manager",
        year: "2017",
        month: "Feb",
        company: "REDspace",
        responsibilities: [
          "Managed all members of the games team. Both in technical/performance guidance, and HR management.", // (Monthly 1:1 meetings, performance evaluations, salary changes, timesheets, vacation approval, personal issues, and career guidance.)",
          "Provided technical direction for client projects.",
        ],
      },
      {
        title: "Games Technical Lead",
        year: "2015",
        month: "Oct",
        company: "REDspace",
        responsibilities: [
          "Managed all members of the games team. Both in technical/performance guidance, and HR management.", // (Monthly 1:1 meetings, performance evaluations, salary changes, timesheets, vacation approval, personal issues, and career guidance.)",
          "Provided technical direction for client projects.",
        ],
      },
      {
        title: "Writer",
        year: "2015",
        month: "",
        company: "Packt Publishing",
        responsibilities: [
          "Wrote the book “Haxe Game Development Essentials” over the course of six months", // (Monthly 1:1 meetings, performance evaluations, salary changes, timesheets, vacation approval, personal issues, and career guidance.)",
          "Developed technical examples to instruct readers on how to develop games using Haxe and Haxeflixel with no prior language knowledge.",
        ],
      },
      {
        title: "Solutions Developer",
        year: "2012",
        month: "May",
        company: "REDspace",
        responsibilities: [
          "Wrote the book “Haxe Game Development Essentials” over the course of six months", // (Monthly 1:1 meetings, performance evaluations, salary changes, timesheets, vacation approval, personal issues, and career guidance.)",
          "Developed technical examples to instruct readers on how to develop games using Haxe and Haxeflixel with no prior language knowledge.",
        ],
      },
      {
        title: "Junior Solutions Developer",
        year: "2011",
        month: "May",
        company: "REDspace",
        responsibilities: [
          "Wrote the book “Haxe Game Development Essentials” over the course of six months", // (Monthly 1:1 meetings, performance evaluations, salary changes, timesheets, vacation approval, personal issues, and career guidance.)",
          "Developed technical examples to instruct readers on how to develop games using Haxe and Haxeflixel with no prior language knowledge.",
        ],
      },
      {
        title: "Internship",
        year: "2011",
        month: "Apr",
        company: "REDspace",
        responsibilities: [
          "Wrote the book “Haxe Game Development Essentials” over the course of six months", // (Monthly 1:1 meetings, performance evaluations, salary changes, timesheets, vacation approval, personal issues, and career guidance.)",
          "Developed technical examples to instruct readers on how to develop games using Haxe and Haxeflixel with no prior language knowledge.",
        ],
      },
    ],
    education: [
      {
        title: "Internship",
        date: "2011",
        company: "REDspace",
        responsibilities: [
          "Wrote the book “Haxe Game Development Essentials” over the course of six months", // (Monthly 1:1 meetings, performance evaluations, salary changes, timesheets, vacation approval, personal issues, and career guidance.)",
          "Developed technical examples to instruct readers on how to develop games using Haxe and Haxeflixel with no prior language knowledge.",
        ],
      },
    ],
  },
};

const workExperience = ResumeData.sections.workExperience.map((item) => (
  <Timeline
    year={item.year}
    month={item.month}
    title={item.title}
    subtitle={item.company}
    lineItems={item.responsibilities}
  />
));

/**
 * <li className="timelineLineItem">
    <div className="timelineItemHeader">
      <div className="timelineCircle">
        {item.month != "" ? <p className="timelineMonth">{item.month}</p> : ""}
        <p>{item.year}</p>
      </div>

      <div className="timelineItemTitle blockHeaderSmall">
        <p className="timelineItemTitleText">{item.title}</p>
        <br />
        <p className="timelineItemTitleCompany">{item.company}</p>
      </div>
    </div>

    <div className="timelineItemDescription">
      <ul>
        {item.responsibilities.map((responsibility) => (
          <li>
            <p class="blockTextDetailsWhite">{responsibility}</p>
          </li>
        ))}
      </ul>
    </div>
  </li>
 */

const Resume = () => {
  return (
    <>
      {/*<!-- Column -->*/}
      <div className="contentColumn">
        {/*<!-- Content -->*/}
        <div className="contentColumnFullHeight">
          <div className="contentblock">
            <div className="blockHeader">
              <p>Work Experience</p>
            </div>
            <div className="contentItem">
              <div className="verticalLineParent">
                {/*<!-- Left vertical line -->*/}
                <div className="verticalLine" />

                {/*<!-- The timeline items container -->*/}
                <ul className="timelineContainer">{workExperience}</ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resume;
