import React from "react";
import { Link } from "react-router-dom";
import "../../css/main.css";
import "./style.css";

const PageHeader = () => {
  return (
    <header className="pageHeader">
      <nav>
        <Link to="/">
          <div id="pageLogo"></div>
        </Link>
        <div className="navLinks">
          <div className="navLink">
            <Link to="/">
              <div id="ProjectsLink"></div>
            </Link>
          </div>
          <div className="navLink">
            <Link to="/resume">
              <div id="ResumeLink" />
            </Link>
          </div>
          <div className="navLink">
            <div id="AboutLink" />
          </div>
        </div>
        <div className="navSpacer"></div>
        <div className="externalLinks">
          <a href="https://twitter.com/JeremyMcCurdy" className="externalLink">
            <img alt="Twitter" src="img/Twitter.png" />
          </a>
          <a href="https://germygames.com" className="externalLink">
            <img alt="Germy Games" src="img/GermyCircleLogo.png" />
          </a>
        </div>
      </nav>
    </header>
  );
};

export default PageHeader;
