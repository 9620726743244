import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import PageHeader from "./components/pageHeader/pageHeader";
import Projects from "./components/projects";
import Resume from "./components/resume/resume";

function App() {
  return (
    <Router>
      <div>
        <PageHeader />
        <main>
          <Switch>
            <Route path="/resume">
              <Resume />
            </Route>
            <Route path="/about">
              <div></div>
              {/*<About />*/}
            </Route>
            <Route path="/">
              <Projects />
            </Route>
          </Switch>
        </main>
      </div>
    </Router>
  );
}

export default App;
