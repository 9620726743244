import React from "react";
import "../../css/main.css";
import "./style.css";

const Timeline = ({ month = "", year, title, subtitle, lineItems = [""] }) => {
  return (
    <>
      <li className="timelineLineItem">
        {/*<!-- The circle and title -->*/}
        <div className="timelineItemHeader">
          {/* <!-- The circle -->*/}
          <div className="timelineCircle">
            {month !== "" ? <p className="timelineMonth">{month}</p> : ""}
            <p>{year}</p>
          </div>

          {/*<!-- The title -->*/}
          <div className="timelineItemTitle blockHeaderSmall">
            <p className="timelineItemTitleText">{title}</p>
            <br />
            <p className="timelineItemTitleCompany">{subtitle}</p>
          </div>
        </div>

        {/* <!-- The description -->*/}
        <div className="timelineItemDescription">
          <ul>
            {lineItems.map((itemValue) => (
              <li>
                <p class="blockTextDetailsWhite">{itemValue}</p>
              </li>
            ))}
          </ul>
        </div>
      </li>
    </>
  );
};

export default Timeline;
